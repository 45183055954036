import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation,useParams } from 'react-router-dom';
import Markdown from 'react-markdown'
import { ConversationsContext, Message as MessageType, Conversation } from '../contexts/ConversationsContext';
import { LoginContext } from '../contexts/LoginContext';
import { encodeInteger, decodeToInteger } from '../utils/encode_id';

interface InputAreaProps {
  hasMessages?: boolean;
  sidebarOpen?: boolean;
  children?: React.ReactNode;
}

const PanelContainer = styled.div<{ hasMessages: boolean; sidebarOpen?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--color-bg-primary);
  overflow: auto;
  justify-content: ${props => props.hasMessages ? 'flex-start' : 'center'};
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (min-width: calc(var(--md) + 1px)) {
    position: relative;
    margin-left: ${props => props.sidebarOpen ? '250px' : '0'};
    max-width: ${props => props.sidebarOpen ? 'calc(100vw - 250px)' : '100vw'};
    transition: margin-left 0.3s ease, max-width 0.3s ease;
  }

  @media (max-width: var(--md)) {
    margin-left: 0 !important;
    max-width: 100% !important;
    transform: none !important;
    transition: none !important;
  }
`;

const MessageListWrapper = styled.div<{ sidebarOpen?: boolean; hasMessages?: boolean }>`
  width: calc(100% - 2rem);
  max-width: var(--md);
  margin: 0 auto;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  padding-bottom: ${props => props.hasMessages ? '3rem' : '0'};
  position: relative;
  left: ${props => props.sidebarOpen ? '125px' : '0'};
  height: 100%;
`;

const MessageList = styled.div<{ hasMessages: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  max-height: 100vh;
  margin-right: 0;
  margin-bottom: 1.5rem;
  overflow-y: auto;

  // Hide scrollbar styles
  &::-webkit-scrollbar {
    display: none; // Hide scrollbar for WebKit browsers (Chrome, Safari)
  }

  // For Firefox
  scrollbar-width: none; // Hide scrollbar for Firefox

  // Custom scrollbar styles (if needed)
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const Message = styled.div<{ isUser: boolean; sidebarOpen?: boolean; isShare?: boolean }>`
  max-width: 100%;
  padding: ${(props) => (props.isUser ? '0.3rem 0.6rem;' : '0.5rem 1rem;')};
  border-radius: 1rem;
  word-wrap: break-word;
  white-space: normal;
  ${props => props.isUser ? 'margin: 0;' : ''}
  align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  background-color: ${(props) => (props.isUser ? 'var(--brand-500)' : 'var(--color-bg-tertiary)')};
  color: ${(props) => (props.isUser ? 'var( --white)' : 'var(--black)')};
  max-width: ${props => props.isUser ? '80%' : '100%'};
  transition: all 0.3s ease;
  animation: fadeIn 0.3s ease;
  text-align: left;
  line-height: 1.8;

  &:first-child {
    margin-top: ${props => props.isShare ? '1rem' : '5rem'};
  }

  &:last-child {
    margin-bottom: 4rem;
  }

  @media (min-width: calc(var(--md) + 1px)) {
    position: relative;
    left: 0;
  }

  @media (max-width: var(--md)) {
    left: 0;
  }

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: 482px) {
    margin-top: 0;
    &:last-child {
      margin-bottom: 8rem;
    }
  }

  &:not(:first-child) {
    margin-top: ${(props) => (props.isUser ? '1.5rem' : '0')};
  }

  &.user-message:not(:first-child) {
    margin-top: 3rem; // Increased margin for user messages that are after the first one
  }

  & p {
    margin: 0;
  }

  & ul, & ol {
    margin: 0;
    padding-left: 1.5rem;
    line-height: 1.75;
  }

  & li {
    margin-bottom: 0.8rem;
  }

  & a {
    color: #0062CC;
    target-name: new;
    target-new: tab;

    &:hover {
      text-decoration: underline;
      color: #003366;
    }
    
    &:link, &:visited {
      text-decoration: none;
    }
  }

  &:not(.user-message) p:not(:last-child)::after {
    content: ""; // Insert a line break
    display: block; // Make it a block element
    margin-bottom: 1rem; // Optional: Add spacing after the line break
  }
`;

const InputArea = styled.form<InputAreaProps>`
  // Desktop styles
  position: ${props => (!props.hasMessages ? 'absolute' : 'fixed')};
  bottom: ${props => (!props.hasMessages ? '50%' : '0')};
  left: ${props => props.sidebarOpen ? 'calc(50% + 125px)' : '50%'};
  transform: ${props => !props.hasMessages 
    ? 'translate(-50%, 50%)'
    : 'translateX(-50%)'
  };
  width: 100%;
  max-width: 800px;
  padding: 0rem 1rem 1rem;
  background: var(--color-bg-primary);
  transition: all 0.3s ease;
  z-index: 1000;
  
  // Only change behavior for mobile
  @media (max-width: var(--sm)) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    transform: none;
    padding: 0.75rem;
    padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));
    margin-top: 10rem;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 3.5rem;
  border-radius: 100px;
  background-color: var(--color-bg-secondary);
  position: relative;
`;

const Input = styled.textarea`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  font-size: 1rem;
  margin: 0 auto;
  background-color: transparent;
  color: var(--color-text-primary);
  border: none;
  resize: none;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-text-secondary);
  }

  /* Slim scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-text-secondary);
    border-radius: 10px;
  }
`;

const SendButton = styled.button<{ hasText: boolean }>`
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  background-color: transparent;
  color: var(--color-text-inverse);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  svg path {
    fill: ${({ hasText }) => (hasText ? 'var(--color-secondary)' : 'var(--color-text-secondary)')};
  }
`;

const WelcomeText = styled.div<{ sidebarOpen?: boolean }>`
  color: var(--color-text-primary);
  font-size: 2rem;
  font-weight: 500;
  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: calc(50% + 3rem);
  transform: ${props => props.sidebarOpen ? 'translateY(-50%) translateX(125px)' : 'translateY(-50%)'};
  text-align: center;
  transition: transform 0.3s ease, left 0.3s ease;

  @media (max-width: var(--md)) {
    font-size: 1.5rem;
    transform: translateY(-50%);
    transition: none;
    bottom: calc(50% + 4rem);
  }

  @media (max-width: var(--sm)) {
    font-size: 1.35rem;
    bottom: calc(50% + 5rem);
  }

  @media (min-width: calc(var(--md) + 1px)) {
    left: ${props => props.sidebarOpen ? 'calc(50% + 125px)' : '50%'};
    transform: translate(-50%, -50%);
  }
`;

const LoadingMessage = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  align-self: flex-start;
`;

const LoadingDotSvg = styled.svg`
  width: 8px;
  height: 8px;
  animation: bounce 1.4s infinite ease-in-out both;

  &:first-child {
    width: 12px;
    height: 12px;
  }

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%, 80%, 100% { 
      transform: scale(0);
    } 
    40% { 
      transform: scale(1);
    }
  }
`;

const ExpertInfo = styled.span`
  font-weight: 500;
  font-size: 0.85rem;
  color: var(--content-tertiary-inverse); 
  margin-bottom: 0rem;
  margin-top: 1.2rem;
`;

const SharedChatInfo = styled.p`
  
  text-align: center;
  background-color: var(--color-bg-secondary);
  border-radius: 1.5rem;
  padding: 0.5rem 0.75rem;
  display: inline-block;
  margin: 1rem auto 0;

  &:first-child {
    margin-top: 5rem;
  }
`;

const ScrollButton = styled.button<{ sidebarOpen: boolean; atBottom: boolean }>`
  position: fixed;
  left: ${props => props.sidebarOpen ? 'calc(50% + 125px)' : '50%'};
  transform: translateX(-50%);
  bottom: 100px;
  margin: auto;
  z-index: 1000;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  height: 35px;
  width: 35px;
  background-color: #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  transition: left 0.3s ease, background-color 0.3s ease;
  display: ${props => (props.atBottom ? 'none' : 'flex')}; 

  @media (max-width: 768px) {
    left: 50%; // Keep the same position when sidebar is open on small screens
    bottom: 80px; // Maintain the same bottom position
  }
`;

interface ConversationPanelProps {
  csrfToken: string | null;
  setCsrfToken: React.Dispatch<React.SetStateAction<string | null>>;
  sidebarOpen: boolean;
  shareChatModalOpen: boolean;
  setShareChatButtonVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onNewChat: () => void;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConversationPanel: React.FC<ConversationPanelProps> = ({ csrfToken, setCsrfToken, sidebarOpen, shareChatModalOpen, setShareChatButtonVisible, onNewChat, setSidebarOpen }) => {
  const params = useParams<{ type: string, chat_id: string }>();
  const { type: typeRouteParam, chat_id: chatIdRouteParam } = params;
  const [isShare, setIsShare] = useState(typeRouteParam === "share"); // NOTE: this state is used mostly for UI changes, but in some useEffects that fetch data or handle the websocket, we directly use (typeRouteParam === "share") because asynchronously updating the state with setIsShare takes a while and we want to avoid the useEffects updating data with the the old invalid state again
  const navigate = useNavigate();
  const location = useLocation();
  const { conversations, addConversation, setCurrentChatId, currentChatId } = useContext(ConversationsContext);
  const currentChatIdRef = useRef<number | null>(currentChatId);

  useEffect(() => {
    setIsShare(typeRouteParam === "share");
    if (typeRouteParam !== "chat" && typeRouteParam !== "share") {
      console.error('Invalid route params type must be chat or share but is: ', typeRouteParam);
      navigate("/404");
    } else if (typeRouteParam === "share" && !chatIdRouteParam) { // chatIdRouteParam is shared chat id
      console.error('Invalid route params chat_id is required for /share/:chat_id but is: ', chatIdRouteParam);
      navigate("/404");
    } else if (typeRouteParam === "chat" && !chatIdRouteParam) {
      // Starting a new chat, reset currentChatId
      setCurrentChatId(null);
    }
  }, [typeRouteParam, chatIdRouteParam, navigate, setCurrentChatId]);

  const { user, accessToken, logout } = useContext(LoginContext);
  const conversation = ((typeRouteParam === "chat") && chatIdRouteParam) ? conversations.find(conv => conv.chat_id === decodeToInteger(chatIdRouteParam)) : undefined;
  const [messages, setMessages] = useState<MessageType[]>(conversation?.messages || []);
  const [input, setInput] = useState('');
  const messageListRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const socketRef = useRef<WebSocket | null>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [loadingDots, setLoadingDots] = useState(false);
  const [atBottom, setAtBottom] = useState(true); // Track if user is at the bottom
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false); // Track button visibility

  useEffect(() => {
    // Focus the input field when the component mounts
    inputRef.current?.focus();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    currentChatIdRef.current = currentChatId;
  }, [currentChatId]);

  useEffect(() => {
    console.log('Chat ID changed:', chatIdRouteParam);
    
    // Reset messages when navigating to a new chat
    if (!chatIdRouteParam) {
      console.log('Clearing messages for new chat');
      setMessages([]);
      setInput('');
    }
  }, [chatIdRouteParam]);
  
  useEffect(() => {
    if (!csrfToken) {
      const fetchCsrfToken = async () => {
        try {
          const response = await fetch('/auth/csrf-token', {
            method: 'GET',
            credentials: 'include',
          });
    
          if (response.ok) {
            const token = response.headers.get('X-CSRF-Token');
            if (token) {
              console.log('New CSRF Token fetched');
              setCsrfToken(token);
            } else {
              console.error('CSRF Token not found in response headers');
            }
          } else {
            console.error('Failed to fetch CSRF token');
          }
        } catch (error) {
          console.error('Error fetching CSRF token:', error);
        }
      };
      fetchCsrfToken();
    }
  }, );

  // if existing /chat, load messages
  useEffect(() => {
    if (conversation?.messages && conversation.messages.length > 0) {
      setMessages(conversation.messages);
    }
  }, [conversation?.messages, setMessages]);

  // if shared chat, load messages
  useEffect(() => {
    if ((typeRouteParam === "share") && chatIdRouteParam) {
      setMessages([]);
      const fetchSharedChatMessages = async () => {
        try {
          const response = await fetch(`/api/conversations/shared_chat_messages/${chatIdRouteParam}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
          });

          if (response.status === 401) {
            logout();
          } else if (response.ok) {
            const data = await response.json();
            const formattedMessages = data.map((message: any) => ({
              id: message.message_id,
              text: message.content,
              isUser: message.sender === 'user',
              expert_id: message.expert_id,
              expert_name: message.expert_name,
            }));
            setMessages(formattedMessages);
          } else {
            console.error('Failed to fetch shared chat messages');
          }
        } catch (error) {
          console.error('Error fetching shared chat messages:', error);
        }
      };

      fetchSharedChatMessages();
    }
  }, [typeRouteParam, chatIdRouteParam, location]);

  useEffect(() => {
    setShareChatButtonVisible(!!(currentChatId && messages.length > 0));
  }, [currentChatId, messages, setShareChatButtonVisible]);

  useEffect(() => {
    if (
      !user ||
      !accessToken ||
      ((typeRouteParam === 'chat') && chatIdRouteParam && decodeToInteger(chatIdRouteParam) === currentChatId)
    ) {
      return;
    }

    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    let socket: WebSocket;

    const connectWebSocket = () => {
      const socketUrl = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/api/media?token=${encodeURIComponent(accessToken)}`;
      socket = new WebSocket(socketUrl);
      socketRef.current = socket;

      socket.onopen = () => {
        reconnectAttempts = 0; // Reset reconnect attempts on successful connection
        const payload: {
          event: string;
          user_id: string;
          chat_id: number | null;
          shared_chat_id: string | null;
          dev_mode: boolean;
          selected_expert_ids: number[];
        } = {
          event: 'connected',
          user_id: user.user_id,
          chat_id: null, // if this is null, backend will create a new chat
          shared_chat_id: null,
          dev_mode: ['true', '1'].includes(localStorage.getItem('dev_mode') || ''),
          selected_expert_ids: [1,2,17,12],
        };

        if (!chatIdRouteParam && currentChatIdRef.current !== null && messages.length > 0) {
          // Reconnect to the same chat only if it has messages
          payload.chat_id = currentChatIdRef.current;
        } else if (typeRouteParam === 'chat' && chatIdRouteParam) {
          // Continue existing chat with chatIdRouteParam
          payload.chat_id = decodeToInteger(chatIdRouteParam);
          setCurrentChatId(payload.chat_id);
        } else if (typeRouteParam === 'share' && chatIdRouteParam) {
          // Continue a shared chat as a separate conversation
          payload.shared_chat_id = chatIdRouteParam;
        }
        // If none of the above, a new chat will be created by the backend

        socket.send(JSON.stringify(payload));
        console.log('WebSocket connection established');
        console.log('Sent connect message:', payload, 'at', new Date().toISOString(), location.pathname);
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received message from WebSocket:', data);

        if (data.event === 'connected') {
          console.log('Connection confirmed by server');

          if (
            // Enter if either new chat (/chat and not chatIdRouteParam) or shared chat to be continued (/share)
            ((typeRouteParam === 'chat' && !chatIdRouteParam) ||
              (typeRouteParam === 'share' && chatIdRouteParam)) &&
            data.chat_id
          ) {
            setCurrentChatId(data.chat_id);
          }
        } else if (data.event === 'setupDone') {
          console.log('Server setup completed');
        } else if (data.event === 'text') {
          setMessages((prevMessages) => {
            // Find the last message from the same expert
            const reversedIndex = [...prevMessages].reverse().findIndex(
              msg => !msg.isUser && msg.expert_id === data.expert_id
            );
            const lastExpertMessageIndex = reversedIndex === -1 ? -1 : prevMessages.length - 1 - reversedIndex;
 
            // Check if there are any user messages after the last expert message
            const hasUserMessageAfterExpert = prevMessages
              .slice(lastExpertMessageIndex + 1)
              .some(msg => msg.isUser);
            
            // If found a previous message from same expert and no user messages after it, append
            if (lastExpertMessageIndex !== -1 && !hasUserMessageAfterExpert) {
              const updatedMessages = [...prevMessages];
              updatedMessages[lastExpertMessageIndex] = {
                ...updatedMessages[lastExpertMessageIndex], 
                text: updatedMessages[lastExpertMessageIndex].text + data.text
              };
              return updatedMessages;
            }
            // Otherwise create new message
            return [...prevMessages, {
              id: data.item_id,
              text: data.text,
              isUser: false,
              expert_id: data.expert_id,
              expert_name: data.expert_name,
            }];
          });
          setLoading(false);
        } else if (data.event === 'truncated') {
          if (data.rules_violated) {
            setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== data.item_id));
          }
        } else if (data.event === 'message_complete') {
          setLoadingDots(false);
          console.log('Message complete event received');
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        setLoading(false);
        socket.close();
        attemptReconnect();
      };

      socket.onclose = (event) => {
        if (event.code === 1008) {
          console.log('WebSocket authentication failed (1008 Policy Violation). Logging out.');
          logout();
        } else if (event.code === 1005 || event.code === 1001) {
          const reason = event.code === 1005 ? 'No Status Rcvd' : 'Going Away';
          console.log(`WebSocket connection closed (${event.code} ${reason}). Probably navigated away from chat.`);
        }
        else if (event.code === 4004) { // Custom code, id not found
          console.log(`WebSocket connection closed (4004). ${typeRouteParam === "share" ? 'Shared chat' : 'Chat'} not found.`);
          const link = `https://${window.location.host}/${typeRouteParam === "share" ? 'share' : 'chat'}/${chatIdRouteParam}`;
          setMessages([{
            id: 'error',
            text: `Unable to load the chat for link ${link}, it may have been deleted ${typeRouteParam === "share" ? 'by the creator' : ''}.`,
            isUser: false,
            expert_id: undefined,
            expert_name: undefined,
          }]);
        }
        else {
          socket.close();
          console.log(`WebSocket connection closed (${event.code}). Attempting to reconnect.`);
          attemptReconnect();
        }
      };
    };

    const attemptReconnect = () => {
      if (reconnectAttempts < maxReconnectAttempts) {
        reconnectAttempts += 1;
        const reconnectDelay = Math.min(500 * 2 ** reconnectAttempts, 5000); // Exponential backoff up to 5 seconds
        console.log(`Attempting to reconnect in ${reconnectDelay / 1000} seconds...`);
        setTimeout(() => {
          connectWebSocket();
        }, reconnectDelay);
      } else {
        setMessages((prevMessages) => {
          const msgText = 'Unable to connect. Please try again later.';
          const latestMessage = prevMessages[prevMessages.length - 1];
          if (latestMessage && latestMessage.text === msgText) {
            return prevMessages;
          }
          return [
            ...prevMessages,
            {
              id: Date.now().toString(),
              text: msgText,
              isUser: false,
            },
          ];
        });
      }
    };

    connectWebSocket();

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [csrfToken, user, accessToken, chatIdRouteParam]); // TODO need to test if this works correctly without isShare in dependencies

  // Function to send message via WebSocket
  const sendMessage = (userMessage: MessageType) => {
    try {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        console.log("Sending message to WebSocket:", JSON.stringify({
          event: "text", text: userMessage.text
       }));
        socketRef.current.send(JSON.stringify({
           event: "text", text: userMessage.text
        }));
      } else {
        throw new Error('WebSocket is not open');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => [...prevMessages, {
        id: Date.now().toString(),
        text: "Unable to send message. Please try again later.",
        isUser: false,
      }]);
    } finally {
      setLoading(false);
      inputRef.current?.focus(); // Re-focus the input after sending a message
    }
  };

  const saveChat = () => {
    try {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        const payload = { event: "save" };
        console.log("Sending save chat message to WebSocket:", JSON.stringify(payload));
        socketRef.current.send(JSON.stringify(payload));
      } else {
        throw new Error('WebSocket is not open');
      }
    } catch (error) {
      console.error('Error sending save chat message:', error);
    }
  };

  useEffect(() => {
    if (shareChatModalOpen) {
      saveChat();
    }
  }, [shareChatModalOpen]);

    // Function to adjust the height of the textarea
  const adjustTextareaHeight = (textarea: HTMLTextAreaElement, container: HTMLDivElement) => {
      textarea.style.height = 'auto'; // Reset textarea height
      const newHeight = Math.min(textarea.scrollHeight, 5 * 1.5 * 16); // Calculate new height up to 5 lines
      textarea.style.height = `${newHeight}px`; // Set textarea height
      container.style.height = `${newHeight + 16}px`; // Set container height with padding
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Combine multiple approaches to force keyboard dismissal
    setTimeout(() => {
      // Blur the textarea
      if (inputRef.current) {
        inputRef.current.blur();
      }
      
      // Blur any focused element
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      
      // Create and remove a temporary input to force keyboard dismissal
      const tmp = document.createElement('input');
      document.body.appendChild(tmp);
      tmp.focus();
      tmp.blur();
      document.body.removeChild(tmp);
      
      // For iOS devices
      window.scrollTo(0, 0);

      // Re-focus the input after a short delay
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 200);
    }, 100);

    if (input.trim()) {
      // If this is a new chat (no chatIdRouteParam in route params)
      if (!chatIdRouteParam) {
        onNewChat();  // Call this when creating a new chat
      }

      if (messages.length === 0) {
        const newConversation: Conversation = {
          chat_id: currentChatId!,
          conversation_title: "New Chat",
          last_message_timestamp: new Date().toISOString(),
          messages: []
        };
        addConversation(newConversation);
        console.log('Added new conversation with chat_id:', currentChatId);
        window.history.pushState(null, '', `/chat/${encodeInteger(currentChatId!)}`);
      }

      if (isShare && messages.length > 0) {
        setIsShare(false);
        const newConversation: Conversation = {
          chat_id: currentChatId!,
          conversation_title: "New Chat",
          last_message_timestamp: new Date().toISOString(),
          messages: messages
        };
        addConversation(newConversation);
        console.log(`Added new conversation with chat_id ${currentChatId} as a continuation of a shared chat ${chatIdRouteParam}`);
      }
      
      const userMessage: MessageType = {
        id: Date.now().toString(),
        text: input.trim(),
        isUser: true,
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput('');
      setLoading(true);
      setLoadingDots(true);
      sendMessage(userMessage);
      inputRef.current!.rows = 1;
      inputRef.current!.style.height = 'auto';
      inputContainerRef.current!.style.height = 'auto';
    }
  };

  useEffect(() => {
    if (window.matchMedia('(max-width: var(--md))').matches) { // Adjust pixel value to match your --md variable
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, []);

  const handlePanelClick = (e: React.MouseEvent) => {
    // Don't collapse sidebar if clicking within the InputArea
    const root = document.documentElement;
    const mdBreakpoint = getComputedStyle(root).getPropertyValue('--md').trim();
    if (window.innerWidth <= parseInt(mdBreakpoint) && sidebarOpen && !(e.target as HTMLElement).closest('form')) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (messageListRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
        const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1;
        setAtBottom(isAtBottom); // Update atBottom state
      }
    };

    const messageList = messageListRef.current;
    messageList?.addEventListener('scroll', handleScroll);

    return () => {
      messageList?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const messageList = messageListRef.current;
    const hasOverflow = messageList ? messageList.scrollHeight > messageList.clientHeight : false;

    // Update scroll button visibility based on overflow and atBottom state
    setScrollButtonVisible(hasOverflow);
  }, [messages, atBottom]); // Ensure this runs whenever messages or atBottom changes

  useEffect(() => {
    // Check if atBottom state is true, if so, hide the scroll button
    setScrollButtonVisible(!atBottom);
  }, [atBottom]); // This effect runs whenever atBottom change

  // Function to scroll to the bottom of the panel
  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight + 20 * 16, // Scroll to bottom plus 20rem
        behavior: 'smooth', // Enable smooth scrolling
      });
    }
  };

  return (
    <PanelContainer 
      ref={panelRef}
      hasMessages={messages.length > 0} 
      sidebarOpen={sidebarOpen}
      onClick={handlePanelClick}
    >
      {scrollButtonVisible && messages.length > 0 && ( // Show button only if not at bottom and there are messages
        <ScrollButton 
          onClick={scrollToBottom} 
          sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false} 
          atBottom={!scrollButtonVisible}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <span style={{ color: 'white' }}>
            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.87651 9.76689V1.56689C4.87651 1.28356 4.97235 1.04606 5.16401 0.854395C5.35568 0.662728 5.59318 0.566895 5.87651 0.566895C6.15985 0.566895 6.39735 0.662728 6.58901 0.854395C6.78068 1.04606 6.87651 1.28356 6.87651 1.56689V9.76689L9.77651 6.86689C9.95985 6.68356 10.1932 6.59189 10.4765 6.59189C10.7598 6.59189 10.9932 6.68356 11.1765 6.86689C11.3598 7.05023 11.4515 7.28356 11.4515 7.56689C11.4515 7.85023 11.3598 8.08356 11.1765 8.26689L6.57651 12.8669C6.37651 13.0669 6.14318 13.1669 5.87651 13.1669C5.60985 13.1669 5.37651 13.0669 5.17651 12.8669L0.576514 8.26689C0.39318 8.08356 0.301514 7.85023 0.301514 7.56689C0.301514 7.28356 0.39318 7.05023 0.576514 6.86689C0.759847 6.68356 0.99318 6.59189 1.27651 6.59189C1.55985 6.59189 1.79318 6.68356 1.97651 6.86689L4.87651 9.76689Z" fill="white"/>
            </svg>
          </span>
        </ScrollButton>
      )}
      <MessageListWrapper sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false} hasMessages={messages.length > 0}>
        <MessageList ref={messageListRef} hasMessages={messages.length > 0} aria-live="polite">
          {isShare && messages.length > 0 && (
            <SharedChatInfo key="shared-chat-info-top">
              This is a copy of a shared chat
            </SharedChatInfo>
          )}
          {messages.map((message) => (
            <React.Fragment key={`message-fragment-${message.id}`}>
              {message.expert_name && (
                <ExpertInfo key={`expert-info-${message.id}`}>
                  {message.expert_name === 'Consensus' ? `${message.expert_name}` : `Notes from ${message.expert_name}`}
                </ExpertInfo>
              )}
              <Message 
                key={`message-${message.id}`} 
                isUser={message.isUser} 
                sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false}
                isShare={isShare}
                aria-label={`${message.isUser ? 'You' : 'AI'} said: ${message.text}`}
              >
                <Markdown
                  components={{
                    ol: ({children}) => <ol style={{ listStyleType: 'decimal' }}>{children}</ol>,
                    li: ({children}) => <li style={{ gap: '0.5rem' }}>{children}</li>,
                    p: ({children}) => <p style={{ margin: 0 }}>{children}</p>
                  }}
                >
                  {message.text}
                </Markdown>
              </Message>
            </React.Fragment>
          ))}
          {loading && (
            <Message isUser={false}>
            </Message>
          )}
          {loadingDots && (
            <LoadingMessage>
              <LoadingDotSvg viewBox="0 0 10 10" width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#6C6E72"/>
              </LoadingDotSvg>
              <LoadingDotSvg viewBox="0 0 10 10" width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#6C6E72"/>
              </LoadingDotSvg>
              <LoadingDotSvg viewBox="0 0 10 10" width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#6C6E72"/>
              </LoadingDotSvg>
            </LoadingMessage>
          )}
          {isShare && messages.length > 0 && (
            <SharedChatInfo key="shared-chat-info-bottom">
              🔒 Messages beyond this point are only visible to you
            </SharedChatInfo>
          )}
        </MessageList>
      </MessageListWrapper>
      {messages.length === 0 && (
        <WelcomeText sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false}>
          How can your experts help today?
        </WelcomeText>
      )}
      <InputArea 
        onSubmit={handleSubmit} 
        hasMessages={messages.length > 0} 
        sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false}
      >
        <InputContainer ref={inputContainerRef}>
          <Input
            ref={inputRef}
            value={input}
            rows={1}
            onChange={(e) => {
              setInput(e.target.value);
              adjustTextareaHeight(e.target as HTMLTextAreaElement, inputContainerRef.current!);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevents the default newline behavior
                handleSubmit(e); // Calls the submit function when Enter is pressed
              }
            }}
            placeholder="Ask your expert anything"
            aria-label="Type your message"
            disabled={loading}
          />
          <SendButton type="submit" aria-label="Send message" hasText={input.trim().length > 0} disabled={loading}>
            <svg width="25" height="25" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0_420_146" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="2" width="29" height="35">
                <rect x="1.78711" y="2.79999" width="28.1971" height="33.6" fill="var(--color-btn-fill)" />
              </mask>
              <g mask="url(#mask0_420_146)">
                <path d="M25.0495 20.895L6.95636 29.995C6.56473 30.1817 6.19268 30.1409 5.84022 29.8725C5.48776 29.6042 5.31152 29.2134 5.31152 28.7V10.5C5.31152 9.98669 5.48776 9.59585 5.84022 9.32752C6.19268 9.05919 6.56473 9.01835 6.95636 9.20502L25.0495 18.305C25.5391 18.5617 25.7838 18.9934 25.7838 19.6C25.7838 20.2067 25.5391 20.6384 25.0495 20.895ZM7.66129 26.6L21.5836 19.6L7.66129 12.6V17.5L14.7106 19.6L7.66129 21.7V26.6Z" fill="var(--color-text-secondary)" />
              </g>
              <mask id="mask1_420_146" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="29" height="34">
                <rect x="0.612305" width="28.1971" height="33.6" fill="var(--color-btn-fill)" />
              </mask>
              <g mask="url(#mask1_420_146)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0236 11.655C22.0837 12.775 21.6137 14.14 21.6137 15.75C21.6137 14.14 21.1438 12.775 20.2039 11.655C19.264 10.535 18.1185 9.97501 16.7674 9.97501C18.1185 9.97501 19.264 9.41501 20.2039 8.29501C21.1438 7.17501 21.6137 5.81001 21.6137 4.20001C21.6137 5.81001 22.0837 7.17501 23.0236 8.29501C23.9635 9.41501 25.109 9.97501 26.4601 9.97501C25.109 9.97501 23.9635 10.535 23.0236 11.655Z" fill="var(--color-text-secondary)" />
              </g>
            </svg>
          </SendButton>
        </InputContainer>
      </InputArea>    
      {messages.length === 0 && (
        <WelcomeText sidebarOpen={window.innerWidth > 768 ? sidebarOpen : false}>
          How can your experts help today?
        </WelcomeText>
      )}
    </PanelContainer>
  );
};

export default ConversationPanel;