import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';

const SettingsContainer = styled.div`
  padding: 2rem;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  min-height: 100%; // Add this line
  flex: 1; // Add this line
  display: flex; // Add this line
  flex-direction: column; // Add this line
`;

const Title = styled.h2`
  color: var(--color-text-primary);
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: var(--color-text-primary);
`;

const Input = styled.input<{ readOnly?: boolean }>`
  border-radius: 100px;
  padding: 0.5rem;
  background-color: ${props => props.readOnly ? 'var(--color-text-secondary)' : 'var(--color-bg-ternary)'};
  color: ${props => props.readOnly ? '#72767D' : 'var(--color-text-primary)'};
  cursor: ${props => props.readOnly ? 'not-allowed' : 'text'};
`;

const ReadOnlyMessage = styled.span`
  font-size: 0.8rem;
  color: #888;
  margin-top: 0.25rem;
`;

const Button = styled.button`
  border-radius: 100px;
  padding: 0.5rem 1rem;
  background: var(--color-secondary);
  color: var(--color-text-inverse);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const KrogerSection = styled.div`
  margin-top: 2rem;
`;

interface KrogerStatusProps {
  isLinked: boolean;
}

const KrogerStatus = styled.p<KrogerStatusProps>`
  color: ${props => props.isLinked ? 'var(--color-success)' : 'var(--color-error)'};
`;

const SuccessMessage = styled.span`
  font-size: 0.8rem;
  color: var(--color-success);
  margin-top: 0.25rem;
`;

const LogoutButton = styled(Button)`
  margin-top: 2rem;
  align-self: flex-start;

  border-radius: 100px;
  padding: 0.5rem 1rem;
  background: var(--color-text-primary);
  color: var(--color-text-inverse);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const SharedChatsSection = styled.div`
  margin-top: 2rem;
`;

const SharedChatsList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const SharedChatItem = styled.div`
  padding: 1rem;
  background-color: var(--color-bg-secondary);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SharedChatLink = styled.a`
  color: var(--color-link);
  text-decoration: underline;
  word-break: break-all;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;

  &:hover svg {
    color: var(--color-error);
  }

  svg {
    width: 20px;
    height: 20px;
    color: #72767d;
  }

  &:hover::after {
    content: 'Unshare chat';
    position: absolute;
    top: -25px;
    right: 0;
    background-color: var(--color-bg-primary);
    color: var(--color-text-primary);
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 0.75rem;
    white-space: nowrap;
  }
`;

interface SharedChat {
  shared_chat_id: string;
  created_at: string;
  chat_title: string;
}

const UserSettings: React.FC = () => {
  const [name, setName] = useState('');
  const [krogerLinked, setKrogerLinked] = useState(false);
  const [krogerEmail, setKrogerEmail] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [sharedChats, setSharedChats] = useState<SharedChat[]>([]);
  const { user, accessToken } = useContext(LoginContext);
  const { isLoggedIn, logout } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user.name || '');
    }
    // fetchKrogerStatus();
    fetchSharedChats();
  }, [user]);

  const fetchKrogerStatus = async () => {
    try {
      const response = await fetch('/auth/kroger/status', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setKrogerLinked(data.is_linked);
        setKrogerEmail(data.account_email || '');
      }
    } catch (error) {
      console.error('Error fetching Kroger status:', error);
    }
  };

  const fetchSharedChats = async () => {
    try {
      const response = await fetch('/api/conversations/user_shared_chats', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSharedChats(data);
      } else {
        console.error('Failed to fetch shared chats');
      }
    } catch (error) {
      console.error('Error fetching shared chats:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/auth/users/me', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ name }),
      });

      if (response.ok) {
        const data = await response.json();
        setName(data.name);
        setUpdateSuccess(true);
        setTimeout(() => setUpdateSuccess(false), 3000); // Hide message after 3 seconds
      } else {
        console.error('Failed to update settings');
        alert('Failed to update name. Please try again.');
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      alert('An error occurred while updating the name. Please try again.');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleLinkKroger = async () => {
    try {
      const response = await fetch('/auth/kroger/link', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.authorization_url;
      }
    } catch (error) {
      console.error('Error linking Kroger account:', error);
    }
  };

  const handleUnlinkKroger = async () => {
    try {
      const response = await fetch('/auth/kroger/unlink', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        setKrogerLinked(false);
        setKrogerEmail('');
      }
    } catch (error) {
      console.error('Error unlinking Kroger account:', error);
    }
  };

  const handleUnshareChat = async (sharedChatId: string) => {
    try {
      const response = await fetch('/api/conversations/unshare_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ shared_chat_id: sharedChatId }),
      });
      if (response.ok) {
        setSharedChats(prev => prev.filter(chat => chat.shared_chat_id !== sharedChatId));
      } else {
        console.error('Failed to unshare chat');
      }
    } catch (error) {
      console.error('Error unsharing chat:', error);
    }
  };

  return (
    <SettingsContainer>
      <Title>User Settings</Title>
      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {updateSuccess && <SuccessMessage>Name updated successfully</SuccessMessage>}
        </InputGroup>
        <InputGroup>
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Input
            id="phoneNumber"
            type="tel"
            placeholder="Phone Number"
            value={user?.phone_number || ''}
            readOnly
          />
          <ReadOnlyMessage>Phone number cannot be changed</ReadOnlyMessage>
        </InputGroup>
        <Button type="submit">Save Settings</Button>
      </Form>
      
      {/* 
      <KrogerSection>
        <h3>Kroger Account</h3>
        {krogerLinked ? (
          <>
            <KrogerStatus isLinked={true}>Linked</KrogerStatus>
            {krogerEmail && <p>Kroger Email: {krogerEmail}</p>}
            <Button onClick={handleUnlinkKroger}>Unlink Kroger Account</Button>
          </>
        ) : (
          <>
            <KrogerStatus isLinked={false}>Not Linked</KrogerStatus>
            <Button onClick={handleLinkKroger}>Link Kroger Account</Button>
          </>
        )}
      </KrogerSection>
      */} 
      <SharedChatsSection>
        <h3>Shared Chats</h3>
        <SharedChatsList>
          {sharedChats.length > 0 ? (
            sharedChats.map(chat => (
              <SharedChatItem key={chat.shared_chat_id}>
                <div>
                  <p><strong>Title:</strong> {chat.chat_title}</p>
                  <p><strong>Created At:</strong> {new Date(chat.created_at).toLocaleString()}</p>
                  <p>
                    <strong>Link:</strong>{' '}
                    <SharedChatLink
                      href={`https://${window.location.host}/share/${chat.shared_chat_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`https://${window.location.host}/share/${chat.shared_chat_id}`}
                    </SharedChatLink>
                  </p>
                </div>
                <DeleteButton onClick={() => handleUnshareChat(chat.shared_chat_id)}>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.5555 4C10.099 4 9.70052 4.30906 9.58693 4.75114L9.29382 5.8919H14.715L14.4219 4.75114C14.3083 4.30906 13.9098 4 13.4533 4H10.5555ZM16.7799 5.8919L16.3589 4.25342C16.0182 2.92719 14.8226 2 13.4533 2H10.5555C9.18616 2 7.99062 2.92719 7.64985 4.25342L7.22886 5.8919H4C3.44772 5.8919 3 6.33961 3 6.8919C3 7.44418 3.44772 7.8919 4 7.8919H4.10069L5.31544 19.3172C5.47763 20.8427 6.76455 22 8.29863 22H15.7014C17.2354 22 18.5224 20.8427 18.6846 19.3172L19.8993 7.8919H20C20.5523 7.8919 21 7.44418 21 6.8919C21 6.33961 20.5523 5.8919 20 5.8919H16.7799ZM17.888 7.8919H6.11196L7.30423 19.1057C7.3583 19.6142 7.78727 20 8.29863 20H15.7014C16.2127 20 16.6417 19.6142 16.6958 19.1057L17.888 7.8919ZM10 10C10.5523 10 11 10.4477 11 11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V11C13 10.4477 13.4477 10 14 10Z"
                    />
                  </svg>
                </DeleteButton>
              </SharedChatItem>
            ))
          ) : (
            <p>No shared chats shared yet.</p>
          )}
        </SharedChatsList>
      </SharedChatsSection>
      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </SettingsContainer>
  );
};

export default UserSettings;