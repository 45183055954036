import React, { useState, useEffect, useContext } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Markdown from 'react-markdown'
import styled from 'styled-components';
import Header from './Header';
import Sidebar from './Sidebar';
import ConversationPanel from './ConversationPanel';
import ShareChatModal from './ShareChatModal';
import Login from './Login';
import UserSettings from './UserSettings';
import OAuthCallback from './OAuthCallback'
import MainPage from './MainPage';
import Secret from './Secret';
import { LoginContext } from '../contexts/LoginContext';
import NotFound from './NotFound';
import Terms from './Terms';
import { termsAndConditions, privacyPolicy } from '../terms';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const MainContent = styled.main<{ sidebarOpen: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
  margin-left: ${props => props.sidebarOpen ? '250px' : '0'};
  margin-top: 60px;
  padding: 0;
  overflow-y: auto;
  * {
    border: none;
  }
`;

interface AppContentProps {
  csrfToken: string | null;
  setCsrfToken: React.Dispatch<React.SetStateAction<string | null>>;
}

const AppContent: React.FC<AppContentProps> = ({ csrfToken, setCsrfToken }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [shareChatModalOpen, setShareChatModalOpen] = useState(false);
  const [shareChatButtonVisible, setShareChatButtonVisible] = useState(false);
  const { isLoggedIn, isLoading } = useContext(LoginContext);
  const [newChatCreated, setNewChatCreated] = useState(false);
  const [reloadConversationPanel, setReloadConversationPanel] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const root = document.documentElement;
      const mdBreakpoint = getComputedStyle(root).getPropertyValue('--md').trim();
      setSidebarOpen(window.innerWidth > parseInt(mdBreakpoint));
    };
    
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleNewChat = () => {
    console.log("new chat created");
    setNewChatCreated(true);
  };

  useEffect(() => {
    if (newChatCreated) {
      setNewChatCreated(false);
    }
  }, [newChatCreated]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={<MainPage csrfToken={csrfToken} />} />
      <Route path="/login" element={<Login csrfToken={csrfToken} setCsrfToken={setCsrfToken} />} />
      <Route
        path="/:type/:chat_id?"
        element={
          isLoggedIn ? (
            <AppContainer>
              <Sidebar 
                isOpen={sidebarOpen} 
                toggleSidebar={toggleSidebar}
                newChatCreated={newChatCreated}
                setReloadConversationPanel={setReloadConversationPanel}
              />
              <ShareChatModal 
                isOpen={shareChatModalOpen} 
                setIsOpen={setShareChatModalOpen}
              />
              <MainContent sidebarOpen={sidebarOpen}>
                <Header 
                  sidebarOpen={sidebarOpen}
                  toggleSidebar={toggleSidebar}
                  setShareChatModalOpen={setShareChatModalOpen}
                  shareChatButtonVisible={shareChatButtonVisible}
                  setShareChatButtonVisible={setShareChatButtonVisible}
                />
                <ConversationPanel 
                  key={reloadConversationPanel ? 'reload-true' : 'reload-false'}
                  csrfToken={csrfToken} 
                  setCsrfToken={setCsrfToken} 
                  sidebarOpen={sidebarOpen}
                  shareChatModalOpen={shareChatModalOpen}
                  setShareChatButtonVisible={setShareChatButtonVisible}
                  onNewChat={handleNewChat}
                  setSidebarOpen={setSidebarOpen}
                />
              </MainContent>
            </AppContainer>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/settings"
        element={
          isLoggedIn ? (
            <AppContainer>
              <Sidebar 
                isOpen={sidebarOpen} 
                toggleSidebar={toggleSidebar} 
                newChatCreated={newChatCreated}
                setReloadConversationPanel={setReloadConversationPanel}
              />
              <MainContent sidebarOpen={sidebarOpen}>
                <Header 
                  sidebarOpen={sidebarOpen}
                  toggleSidebar={toggleSidebar}
                  setShareChatModalOpen={setShareChatModalOpen}
                  shareChatButtonVisible={shareChatButtonVisible}
                  setShareChatButtonVisible={setShareChatButtonVisible}
                />
                <UserSettings />
              </MainContent>
            </AppContainer>
          ) : (
            <Login csrfToken={csrfToken} setCsrfToken={setCsrfToken} />
          )
        }
      />
      <Route path="/oauth-callback/:provider" element={<OAuthCallback />} />
      <Route 
        path="/password" 
        element={
          <Secret 
            csrfToken={csrfToken} 
            onPasswordVerified={() => {}} 
          />
        } 
      />
      <Route path="/404" element={<NotFound />} />
      <Route path="/terms" element={<Terms title="Terms and Conditions"><Markdown>{termsAndConditions}</Markdown></Terms>} />
      <Route path="/privacy" element={<Terms title="Privacy Policy"><Markdown>{privacyPolicy}</Markdown></Terms>} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default AppContent;
