import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%; /* Inherit system/browser font size */
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem; /* Default size for body text, respects user settings */
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(--black);
  }

  :root {
    --black: #000000;
    --grey-004: #BABABA;
    --light-grey-001: #F6F6F6;
    --medium-grey-002: #EEEEEE;
    --white: #FFFFFF;
    --brand-500: #007AFF;

    --color-primary: #0A0E14;
    --color-secondary: #007AFF;
    --color-tertiary: #61dafb;

    --color-bg-primary: #ffffff;
    --color-bg-secondary: #f6f6f6;
    --color-bg-tertiary: #F6F6F6;
    --color-bg-muted: #D4D4D4;

    --content-tertiary-inverse: #6C6E72;

    --color-btn-fill: #D9D9D9;

    --color-text-primary: #000000;
    --color-text-secondary: #BABABA;
    --color-text-inverse: #ffffff;

    --color-border: #7289DA;
    --color-border-muted: #ced4da;

    --color-success: #4CAF50;
    --color-warning: #ffc107;
    --color-error: #ED4245;
    --color-info: #17a2b8;

    --sm: 482px;
    --md: 768px;
    --lg: 1024px;
    --xl: 1280px;

    // add similar variables for standard font sizes, spacings/paddings/margins, border styles, etc.
  }
`;
