import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  text-align: center;
`;

const NotFoundTitle = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const RedirectText = styled.p`
  font-size: 1.5rem;
  margin-top: 1rem;

  a {
    color: var(--color-secondary);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NotFound: React.FC = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          navigate('/chat');
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <NotFoundContainer>
      <NotFoundTitle>404 Page not found</NotFoundTitle>
      <RedirectText>
        You'll be redirected back in {countdown} seconds. Or click{' '}
        <a href="/chat">here</a> to be redirected immediately.
      </RedirectText>
    </NotFoundContainer>
  );
};

export default NotFound;
