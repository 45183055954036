import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--color-secondary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${SpinnerAnimation} 2s linear infinite;
  margin: 20px auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg-primary);
  text-align: center;
  padding: 20px;
`;

const Message = styled.p`
  color: var(--color-text-primary);
  font-size: 1.2rem;
  margin-top: 20px;
`;

const OAuthCallback: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { provider } = useParams();
  const { loginOAuth } = useContext(LoginContext);
  const [error, setError] = useState<string>('');
  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    const getQueryParams = (): URLSearchParams => {
      return new URLSearchParams(location.search);
    };

    const authenticate = async () => {
      const params = getQueryParams();
      const code = params.get('code');
      const state = params.get('state');
      if (!code) {
        setError('No authorization code found.');
        startCountdown();
        return;
      }
      if (!provider) {
        setError('No Oauth provider specified.');
        startCountdown();
        return;
      }
      const storedState = localStorage.getItem('oauth_state_param');
      if (!state || !storedState || state !== storedState) {
        localStorage.removeItem('oauth_state_param');
        setError('Invalid state parameter. This could indicate a CSRF attack. You will be redirected to login in 5 seconds.');
        startCountdown();
        return;
      }
      localStorage.removeItem('oauth_state_param');
      try {
        const redirect_uri = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${window.location.pathname}`;
        await loginOAuth(code, provider, redirect_uri, state || '');
        navigate('/chat');
      } catch (err) {
        setError('Authentication failed. There seems to be a problem with your credentials. Please try another authentication method. You will be redirected to login in 5 seconds.');
        startCountdown();
      }
    };

    const startCountdown = () => {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            navigate('/login');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {!error ? (
        <>
          <Spinner />
          <Message>Authenticating...</Message>
        </>
      ) : (
        <>
          <Message>{error.replace('5 seconds', `${countdown} seconds`)}</Message>
          <Message>Redirecting in {countdown} seconds...</Message>
        </>
      )}
    </Container>
  );
};

export default OAuthCallback;
