const effectiveDate = "5th December 2024";
export const termsAndConditions = `
Effective Date: ${effectiveDate}

Welcome to ChocolateChip AI ("we," "our," or "us"). By accessing or using our services, including our applications, websites, or platforms (collectively, the "Services"), you ("you" or "User") agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you may not use our Services.

## 1. Use of the Services
### a. Eligibility
You must be at least 18 years old or the age of majority in your jurisdiction to use the Services. By using the Services, you represent and warrant that you meet this requirement.

### b. License
We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for personal or internal business purposes, subject to these Terms. You agree not to:

Reverse engineer, decompile, or modify the Services.
Use the Services for illegal or unauthorized purposes.
Reproduce, distribute, or sell any part of the Services without our prior written consent.

## 2. User Content and Responsibilities
### a. User Content
You may upload, submit, or transmit content (collectively, "User Content") through the Services. By doing so, you grant us a perpetual, irrevocable, worldwide, royalty-free, sublicensable, and transferable license to use, reproduce, modify, distribute, and display your User Content for any lawful purpose.

### b. Prohibited Conduct
You agree not to:

Violate any applicable laws or regulations.
Interfere with the functionality of the Services.
Transmit harmful or malicious code.
We reserve the right to suspend or terminate your access to the Services if you engage in prohibited conduct.

## 3. Intellectual Property
All intellectual property rights in the Services, including software, content, and trademarks, belong to us or our licensors. You acknowledge that you have no ownership rights in the Services or any related content.

## 4. Disclaimers
### a. No Warranties
The Services are provided "as is" and "as available," without any warranties of any kind, express or implied. We disclaim all warranties, including but not limited to:

Merchantability.
Fitness for a particular purpose.
Non-infringement.
Accuracy or reliability of results.

### b. No Guarantee of Availability
We do not guarantee that the Services will be uninterrupted, error-free, or secure. Access to the Services may be restricted or suspended at our discretion.

## 5. Limitation of Liability
To the maximum extent permitted by law:

We are not liable for any indirect, incidental, consequential, special, or punitive damages arising out of your use of the Services.
Our total liability for any claims related to the Services is limited to the amount you paid us in the 12 months preceding the claim or $100, whichever is lower.
Some jurisdictions do not allow the exclusion or limitation of liability for certain damages. In such cases, our liability will be limited to the fullest extent permitted by applicable law.

## 6. Indemnification
You agree to indemnify, defend, and hold harmless ChocolateChip AI, its affiliates, and their officers, directors, employees, and agents from any claims, liabilities, damages, or expenses (including legal fees) arising out of:

Your use of the Services.
Your violation of these Terms.
Your infringement of any intellectual property or other rights.

## 7. Termination
We may suspend or terminate your access to the Services at any time, without notice or liability, if you violate these Terms or engage in conduct we deem harmful to the Services. Upon termination:

Your right to use the Services will cease immediately.
Provisions of these Terms that by their nature should survive termination will remain in effect.

## 8. Governing Law and Dispute Resolution
### a. Governing Law
These Terms are governed by the laws of the State of California, United States, without regard to its conflict of law principles.

b. Dispute Resolution
Any disputes arising out of or relating to these Terms or the Services will be resolved through binding arbitration under the rules of the [American Arbitration Association (AAA)] or similar body. Arbitration will take place in [Insert City], and the decision will be final and binding. Users waive their right to participate in class actions.

## 9. Changes to These Terms
We may modify these Terms at any time. Changes will be effective when posted. Your continued use of the Services after changes are posted constitutes your acceptance of the modified Terms.

## 10. Miscellaneous
### a. Severability
If any provision of these Terms is found to be unenforceable, the remaining provisions will remain in full force and effect.

b. Entire Agreement
These Terms, along with our Privacy Policy, constitute the entire agreement between you and us regarding the Services.

c. No Waiver
Our failure to enforce any provision of these Terms does not constitute a waiver of our rights.

## 11. Contact Us
If you have questions about these Terms, contact us at:

ChocolateChip AI  
87 N Raymond, Suite 600  
Pasadena, CA 91103  

Email: info@chocolatechip.ai  
Phone: (626) 726-4836  
`;

export const privacyPolicy = `
Effective Date: ${effectiveDate}

Chocolatechip AI ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our services, including any applications, websites, or platforms we provide (collectively, the "Services"). By using our Services, you agree to the terms outlined in this Privacy Policy.

## 1. Information We Collect
We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked (directly or indirectly) to you (“Personal Data”). The types of data we collect include:

### a. Information You Provide to Us
Name, email address, and other contact information.
Payment and billing details.
Messages, documents, or files you upload or submit to our Services.

### b. Information We Collect Automatically
Usage data, including device identifiers, browser type, IP address, operating system, and interaction logs.
Cookies, pixels, and similar technologies for tracking behavior.

### c. Generated Data
AI and machine-learning outputs based on your interactions with our Services.
Analytical insights and inferences derived from your usage data.

## 2. How We Use Your Information
We use your data to:

Provide, operate, and improve our Services.
Analyze and enhance user experience, including through machine learning and AI systems.
Develop new products, features, and services.
Communicate with you, including for marketing and customer support purposes.
Comply with legal obligations or protect our rights.
We may use your data for any lawful purpose, including:

Aggregating, anonymizing, and selling usage insights.
Research, testing, and development.
AI model training and improvements.

## 3. How We Share Your Information
We may share your Personal Data with:

Service Providers: Vendors and partners who support our operations (e.g., cloud hosting, analytics).
Affiliates and Subsidiaries: Companies under common control with us.
Law Enforcement and Legal Obligations: When required by law or to protect our legal rights.
Third Parties: For purposes such as advertising, marketing, or partnerships.
a. International Data Transfers
We may transfer your data to locations outside your country, including to the United States or other jurisdictions with different data protection standards. We comply with applicable laws governing such transfers, including GDPR safeguards (e.g., Standard Contractual Clauses).

## 4. Your Rights and Choices
Depending on your location, you may have the following rights regarding your data:

Under the GDPR (EU/EEA Residents):
Access: Request a copy of your data.
Correction: Request updates to inaccurate data.
Erasure: Request deletion of your data ("right to be forgotten").
Portability: Receive your data in a machine-readable format.
Restriction or Objection: Limit or object to data processing.
Withdrawal of Consent: Revoke your consent at any time.
To exercise your rights, email us at [Insert Contact Email].

Under the CCPA (California Residents):
Access and Portability: Request specific pieces of your data.
Deletion: Request deletion of your data.
Opt-Out of Sale: Request exclusion from the sale of your data.
To submit requests, email us at [Insert Contact Email].

## 5. Data Retention
We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce agreements. Aggregated and anonymized data may be retained indefinitely.

## 6. Security
We implement reasonable administrative, technical, and physical safeguards to protect your data. However, no method of transmission or storage is 100% secure, and we cannot guarantee absolute security.

## 7. Children’s Privacy
Our Services are not directed to individuals under 16. We do not knowingly collect Personal Data from children. If we become aware of data collected from children, we will delete it.

## 8. Changes to This Privacy Policy
We may update this Privacy Policy from time to time. The updated version will be posted with a revised “Effective Date.” Continued use of our Services constitutes acceptance of the updated terms.

## 9. Contact Us
If you have questions about this Privacy Policy or wish to exercise your rights, contact us at:

ChocolateChip AI  
87 N Raymond, Suite 600  
Pasadena, CA 91103  

Email: info@chocolatechip.ai  
Phone: (626) 726-4836  
`;