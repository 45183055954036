import React, { useState, KeyboardEvent, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import { useGoogleLogin, CodeResponse } from '@react-oauth/google';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg-tertiary);
`;

const LoginBox = styled.div`
  background-color: var(--color-text-primary);
  padding: 2rem;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: var(--color-text-inverse);
  margin-bottom: 2rem;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: var(--color-bg-secondary);
  color: var(--color-text-primary);
  font-size: 1rem;
  transition: all 0.3s ease;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background: var(--color-bg-primary);
  color: var(--color-text-primary);
  border: none;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-bg-secondary);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: var(--color-error);
  margin-top: 1rem;
`;

const OrText = styled.p`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--color-text-inverse);

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--color-text-inverse);
    margin: 0 0.5em;
  }
`;

interface LoginProps {
  csrfToken: string | null;
  setCsrfToken: (value: string) => void;
}

const Login: React.FC<LoginProps> = ({ csrfToken, setCsrfToken }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState('phone');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, requestCode } = useContext(LoginContext);

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('/auth/csrf-token', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const token = response.headers.get('X-CSRF-Token');
        if (token) {
          console.log('New CSRF Token fetched');
          setCsrfToken(token);
        } else {
          console.error('CSRF Token not found in response headers');
        }
      } else {
        console.error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const handleRequestCode = async () => {
    try {
      await requestCode(phoneNumber);
      setStep('code');
      setError('');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error requesting verification code');
      console.error('Error requesting verification code:', error);
    }
    await fetchCsrfToken();
  };

  const handleVerifyCode = async () => {
    try {
      await login(phoneNumber, verificationCode);
      navigate('/chat');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error verifying code');
      console.error('Error verifying code:', error);
    }
    await fetchCsrfToken();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (step === 'phone') {
        handleRequestCode();
      } else {
        handleVerifyCode();
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code' as const,
    ux_mode: 'redirect',
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/oauth-callback/google`,
    scope: 'profile email openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.addresses.read https://www.googleapis.com/auth/youtube.readonly',
    state: (() => {
        const state = Math.random().toString(36).substring(2);
        localStorage.setItem('oauth_state_param', state);
        return state;
      })(),
      onSuccess: (response: CodeResponse) => {
        navigate(`/oauth-callback/google?code=${response.code}&state=${response.state}`);
      },
    });

  return (
    <LoginContainer>
      <LoginBox>
        <Title>Login</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {step === 'phone' ? (
          <>
          <Button onClick={handleGoogleLogin}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
            Continue with Google
          </Button>
          <OrText>or</OrText>
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button onClick={handleRequestCode}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.62 10.79a15.91 15.91 0 006.59 6.59l2.2-2.2a1 1 0 011.05-.24 11.72 11.72 0 003.68.59 1 1 0 011 1v3.78a1 1 0 01-1 1A17 17 0 013 5a1 1 0 011-1h3.75a1 1 0 011 1 11.72 11.72 0 00.59 3.68 1 1 0 01-.24 1.05z"/>
              </svg>
              Use Phone Number
            </Button>
          </>
        ) : (
          <>
            <Input
              type="text"
              placeholder="Verification Code"
              autoComplete="one-time-code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Button onClick={handleVerifyCode}>Verify Code</Button>
          </>
        )}
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
