import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ConversationsContext } from '../contexts/ConversationsContext';
import { LoginContext } from '../contexts/LoginContext';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-bg-primary);
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 500px;
  height: 25%;
  width: 100%;
  z-index: 2001;
  position: relative;

  @media (max-width: 482px) {
    height: 40%;
    width: 90%;
  }
`;

const ModalHeader = styled.h2`
  margin-bottom: 1rem;
  color: var(--color-text-primary);
`;

const ModalBody = styled.div`
  margin-bottom: 1rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: var(--color-secondary);
  color: var(--color-text-inverse);
  border: none;
  border-radius: 4px;
  width: 30%;
  cursor: pointer;
  font-size: 1rem;
  &:disabled {
    opacity: 0.9;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    opacity: 0.9;
  }
`;

const LinkInput = styled.input`
  padding: 0.5rem;
  flex: 1;
  border: 1px solid var(--color-border-muted);
  border-radius: 4px;
  margin-right: 0.5rem;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
`;

const MessageText = styled.p`
  font-size: 1rem;
  color: var(--color-text-primary);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  line-height: 1;
  color: var(--color-text-primary);
`;
const LoadingText = styled.span`
  &::after {
    content: '';
    display: inline-block;
    width: 1em;
    animation: ellipsis 1.5s infinite;
  }

  @keyframes ellipsis {
    0% {
      content: '';
    }
    33% {
      content: '.';
    }
    66% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }
`;

interface ShareChatModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ShareChatModal: React.FC<ShareChatModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [shareLink, setShareLink] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const { currentChatId } = useContext(ConversationsContext);
  const { accessToken, logout } = useContext(LoginContext);

  const handleCreateLink = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await fetch('/api/conversations/share_chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ chat_id: currentChatId }),
      });

      if (response.status === 401) {
        logout();
        return;
      } else if (!response.ok) {
        throw new Error('Failed to create share link');
      }

      const data = await response.json();
      setShareLink(`https://${window.location.host}/share/${data.shared_chat_id}`);
    } catch (error) {
      setErrorMessage('Error! Sorry, please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setIsCopied(true);
    } catch (error) {
      alert('Failed to copy link');
    }
  };

  if (!isOpen) return null;

  const handleCloseModal = () => {
    setIsOpen(false);
    setShareLink('');
    setIsCopied(false);
  };

  return (
    <>
      <ModalOverlay onClick={handleCloseModal} />
      <ModalContent>
        <CloseButton onClick={handleCloseModal} aria-label="Close modal">
          &times;
        </CloseButton>
        <ModalHeader>Share Chat</ModalHeader>
        <ModalBody>
          {errorMessage ? (
            <MessageText>{errorMessage}</MessageText>
          ) : !shareLink ? (
            <MessageText>
              Would you like to create a shareable link for this Chat? Everyone with the link will be able to view the Chat up to the current message.
            </MessageText>
          ) : (
            <InputRow>
              <MessageText>{shareLink}</MessageText>
              <Button
                onClick={handleCopyLink}
                style={{ opacity: isCopied ? 0.9 : 1 }}
              >
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </InputRow>
          )}
        </ModalBody>
        <ModalFooter>
          {!shareLink && (
            <Button onClick={handleCreateLink} disabled={isLoading}>
              {isLoading ? <LoadingText>Creating</LoadingText> : 'Create Link'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default ShareChatModal;