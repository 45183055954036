import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from './styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import AppContent from './components/AppContent';
import { ConversationsProvider } from './contexts/ConversationsContext';
import { LoginProvider } from './contexts/LoginContext';
import { GoogleOAuthProvider } from "@react-oauth/google";

const App: React.FC = () => {
    const [csrfToken, setCsrfToken] = useState<string | null>(null);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('/auth/csrf-token', {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const token = response.headers.get('X-CSRF-Token');
                    if (token) {
                        console.log('Initial CSRF Token fetched');
                        setCsrfToken(token);
                    } else {
                        console.error('CSRF Token not found in response headers');
                    }
                } else {
                    console.error('Failed to fetch CSRF token');
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        };
        
        window.addEventListener('resize', appHeight);
        window.addEventListener('orientationchange', appHeight);
        
        // Initial call
        appHeight();
        
        return () => {
            window.removeEventListener('resize', appHeight);
            window.removeEventListener('orientationchange', appHeight);
        };
    }, []);

    useEffect(() => {
        document.title = "Chocolate Chip AI"; // Set the document title
    }, []);

    return (
        <ThemeProvider theme={{}}>
          <GlobalStyle />
            <GoogleOAuthProvider clientId="111951200907-i6v7htd1ffj652mj2qo606vi00t77kia.apps.googleusercontent.com">
                <LoginProvider>
                    <Router>
                        <ConversationsProvider>
                            <AppContent 
                            csrfToken={csrfToken}
                            setCsrfToken={setCsrfToken}
                            />
                        </ConversationsProvider>
                    </Router>
                </LoginProvider>
            </GoogleOAuthProvider>
        </ThemeProvider>
    );
};

export default App;
