import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoginContext } from '../contexts/LoginContext';
import { encodeInteger } from '../utils/encode_id';
const SecretContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const PasswordInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 200px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: var(--color-secondary);
  color: var(--color-text-inverse);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

interface SecretProps {
  csrfToken: string | null;
  onPasswordVerified: () => void;
}

const Secret: React.FC<SecretProps> = ({ csrfToken, onPasswordVerified }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { checkAuthStatus } = useContext(LoginContext);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
      };

      if (csrfToken) {
        headers['X-CSRF-Token'] = csrfToken;
      }

      const response = await fetch('/auth/verify_interim_password', {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        await checkAuthStatus();
        onPasswordVerified();
        navigate('/chat');
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Incorrect password');
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      setError('An error occurred while verifying the password');
    }
  };

  return (
    <SecretContainer>
      <form onSubmit={handleSubmit}>
        <PasswordInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <SubmitButton type="submit">Submit</SubmitButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </form>
    </SecretContainer>
  );
};

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

export default Secret;
