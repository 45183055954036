import React, { createContext, useState, useEffect } from 'react';

// Define types
export interface User {
  user_id: string;
  name: string;
  phone_number: string;
}

interface LoginContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: User | null;
  accessToken: string | null;
  login: (phoneNumber: string, code: string) => Promise<void>;
  loginOAuth: (code: string, provider: string, redirect_uri: string, state: string) => Promise<void>;
  logout: () => void;
  checkAuthStatus: () => Promise<void>;
  requestCode: (phoneNumber: string) => Promise<void>;
}

// Create context with default values
export const LoginContext = createContext<LoginContextType>({
  isLoggedIn: false,
  isLoading: true,
  user: null,
  accessToken: null,
  login: async () => {},
  loginOAuth: async () => {},
  logout: () => {},
  checkAuthStatus: async () => {},
  requestCode: async () => {},
});

interface LoginProviderProps {
  children: React.ReactNode;
}

export const LoginProvider: React.FC<LoginProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem('access_token')
  );

  const checkAuthStatus = async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setIsLoggedIn(false);
        setUser(null);
        setIsLoading(false);
        return;
      }

      const response = await fetch('/auth/users/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setIsLoggedIn(true);
      } else {
        // Clear invalid token
        localStorage.removeItem('access_token');
        setIsLoggedIn(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsLoggedIn(false);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const requestCode = async (phoneNumber: string) => {
    const response = await fetch('/auth/request-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone_number: phoneNumber }),
      credentials: 'include',
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Error requesting verification code');
    }
  };

  const login = async (phoneNumber: string, code: string) => {
    try {
      const response = await fetch('/auth/verify-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber, code }),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token);
        localStorage.setItem('access_token', data.access_token);
        await checkAuthStatus();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error verifying code');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const loginOAuth = async (code: string, provider: string, redirect_uri: string, state: string) => {
    try {
      const response = await fetch('/auth/verify-oauth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code, provider, redirect_uri, state
        }),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token);
        localStorage.setItem('access_token', data.access_token);
        await checkAuthStatus();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Error verifying OAuth credentials');
      }
    } catch (error) {
      console.error('OAuth login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    setAccessToken(null);
    setIsLoggedIn(false);
    setUser(null);
  };

  // Check auth status when component mounts
  useEffect(() => {
    checkAuthStatus();
  }, []);

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        user,
        accessToken,
        login,
        loginOAuth,
        logout,
        checkAuthStatus,
        requestCode,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
