import React, { createContext, useState, useEffect, useContext } from 'react';
import { LoginContext } from './LoginContext';

export interface DBMessageSchema {
    message_id: number;
    chat_id: number | null;
    user_id: number;
    expert_id: number | null;
    expert_name: number | null;
    sender: string;
    medium: string;
    content: string;
    timestamp: string;
    hidden: boolean;
  }

export interface Message {
    id: string;
    text: string;
    isUser: boolean;
    expert_id?: string;
    expert_name?: string;
  }

export interface Conversation {
  chat_id: number;
  conversation_title: string;
  last_message_timestamp: string;
  messages?: Message[];
}

interface ConversationsContextType {
  currentChatId: number | null;
  setCurrentChatId: (chat_id: number | null) => void;
  conversations: Conversation[];
  fetchConversations: () => Promise<void>;
  fetchMessages: (chat_ids?: number[]) => Promise<void>;
  addConversation: (conversation: Conversation) => void;
  deleteConversation: (chat_id: number) => Promise<void>;
}

/** 
 * Provides conversation data to components.
 */
export const ConversationsContext = createContext<ConversationsContextType>({
  currentChatId: null,
  setCurrentChatId: () => {},
  conversations: [],
  fetchConversations: async () => {},
  fetchMessages: async () => {},
  addConversation: () => {},
  deleteConversation: async () => {},
});

/** 
 * ConversationsProvider wraps components that need access to conversation data.
 */
export const ConversationsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const { accessToken, isLoggedIn, logout } = useContext(LoginContext);

  /**
   * Fetches the list of conversations for the logged-in user.
   * Logs out the user if the access token is invalid or expired.
   */
  const fetchConversations = async (): Promise<void> => {
    if (!isLoggedIn || !accessToken) return;

    try {
      const response = await fetch('/api/conversations/conversation_list', {
        headers: { 'Authorization': `Bearer ${accessToken}` },
      });

      if (response.status === 401) {
        logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setConversations(prevConversations => {
          const existingIds = new Set(prevConversations.map(conv => conv.chat_id));
          const newConversations = data.filter((conv: Conversation) => !existingIds.has(conv.chat_id));
          const updatedExisting = prevConversations.map(conv => {
            const updated = data.find((newConv: Conversation) => newConv.chat_id === conv.chat_id);
            return updated || conv;
          });
          return [...newConversations, ...updatedExisting].sort((a, b) => 
            new Date(b.last_message_timestamp).getTime() - new Date(a.last_message_timestamp).getTime()
          );
        });
      } else {
        console.error('Failed to fetch conversations');
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  /**
   * Fetches messages for the specified chat IDs.
   * Logs out the user if the access token is invalid or expired.
   * @param chatIds - Array of chat IDs to fetch messages for.
   */
  const fetchMessages = async (chatIds: number[] = []): Promise<void> => {
    if (!isLoggedIn || !accessToken) return;

    try {
      const queryParams = chatIds.length ? `?chat_ids=${chatIds.join(',')}` : '';
      const response = await fetch(
        `/api/conversations/all_user_messages${queryParams}`,
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      );

      if (response.status === 401) {
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const data = await response.json();
      setConversations(prev => prev.map(conv => {
        const matchingMessages = data
          .filter((msg: DBMessageSchema) => msg.chat_id === conv.chat_id)
          .map((msg: DBMessageSchema) => ({
            id: msg.message_id.toString(),
            text: msg.content,
            isUser: msg.sender === 'user',
            expert_id: msg.expert_id,
            expert_name: msg.expert_name
          }));

        const newMessages = new Map<string, Message>([
          ...(conv.messages || []).map((msg: Message) => [msg.id, msg]),
          ...matchingMessages.map((msg: Message) => [msg.id, msg])
        ]);

        return {
          ...conv,
          messages: Array.from(newMessages.values())
        };
      }));
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  /**
   * Deletes a conversation by its chat ID.
   * Logs out the user if the access token is invalid or expired.
   * @param chatId - The ID of the chat to delete.
   */
  const deleteConversation = async (chatId: number): Promise<void> => {
    if (!isLoggedIn || !accessToken) return;

    try {
      const response = await fetch(`/api/conversations/${chatId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${accessToken}` },
      });

      if (response.status === 401) {
        logout();
        return;
      }

      if (response.ok) {
        setConversations(prev => prev.filter(conv => conv.chat_id !== chatId));
      } else {
        console.error('Failed to delete conversation');
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const addConversation = (conversation: Conversation) => {
    setConversations((prev) => [conversation, ...prev]);
  };

  useEffect(() => {
    if (isLoggedIn && accessToken) {
      const initializeData = async () => {
        await fetchConversations();
        await fetchMessages();
      };
      initializeData();
    }
  }, [isLoggedIn, accessToken]);

  return (
    <ConversationsContext.Provider value={{ currentChatId, setCurrentChatId, conversations, fetchConversations, fetchMessages, addConversation, deleteConversation }}>
      {children}
    </ConversationsContext.Provider>
  );
};