const ALPHABET: string = "0m2no1ab34cde5fghi";
const PADDING_SEQUENCE: string = "jpq6rs7tuklv8wxyz9"; // Disjoint from ALPHABET
const BASE: number = ALPHABET.length;

/**
 * Encodes an integer into a base-36 string, pads it to 32 characters, and formats it like a UUID.
 * @param num - The integer to encode.
 * @returns Encoded and formatted string.
 */
function encodeInteger(num: number): string {
    if (num < 0) throw new Error("Only non-negative integers are supported.");
    let encoded: string[] = [];
    while (num > 0) {
        const remainder: number = num % BASE;
        encoded.push(ALPHABET[remainder]);
        num = Math.floor(num / BASE);
    }
    // Reverse the encoded array and join to form the string
    let encodedStr: string = encoded.reverse().join("").toLowerCase();

    // Pad the encoded string to 32 characters using PADDING_SEQUENCE
    if (encodedStr.length < 32) {
        const paddingNeeded: number = 32 - encodedStr.length;
        encodedStr += PADDING_SEQUENCE.slice(0, paddingNeeded);
    }

    // Insert hyphens to format like a UUID: 8-4-4-4-12
    return `${encodedStr.slice(0, 8)}${encodedStr.slice(8, 12) ? '-' + encodedStr.slice(8, 12) : ''}${encodedStr.slice(12, 16) ? '-' + encodedStr.slice(12, 16) : ''}${encodedStr.slice(16, 20) ? '-' + encodedStr.slice(16, 20) : ''}${encodedStr.slice(20, 32) ? '-' + encodedStr.slice(20, 32) : ''}`;
}

/**
 * Decodes a base-36 string back into an integer (case-insensitive).
 * @param base36Str - The encoded string in base-36 format.
 * @returns Decoded integer.
 */
function decodeToInteger(base36Str: string): number {
    // Remove hyphens and convert to lowercase
    const id: string = base36Str.replace(/-/g, '').toLowerCase();
    let decoded: number = 0;
    let paddingStartIndex: number = id.length;

    // Find the start of the padding sequence
    for (let i = 0; i < id.length; i++) {
        if (PADDING_SEQUENCE.includes(id[i])) {
            paddingStartIndex = i;
            break;
        }
    }

    // Decode only the non-padding part
    for (let i = 0; i < paddingStartIndex; i++) {
        const index: number = ALPHABET.indexOf(id[i]);
        if (index === -1) throw new Error(`Invalid character '${id[i]}' in base-${BASE} string.`);
        decoded = decoded * BASE + index;
    }

    return decoded;
}

export { encodeInteger, decodeToInteger };
