import React from 'react';
import styled from 'styled-components';

const TermsContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  font-size: 1rem;
  line-height: 1.6;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 1rem;
`;

const TermsText = styled.div`
  max-height: 85vh;
  overflow-y: auto;

  p {
    margin-bottom: 1rem;
  }

  ul, ol {
    margin: 1rem 0;
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

interface TermsProps {
  title: string;
  children: React.ReactNode;
}

const Terms: React.FC<TermsProps> = ({ title, children }) => {
  return (
    <TermsContainer>
      <Title>{title}</Title>
      <TermsText>
        {children}
      </TermsText>
    </TermsContainer>
  );
};

export default Terms;
