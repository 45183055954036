import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { LoginContext } from '../contexts/LoginContext';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const StyledButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--color-border);
  color: var(--color-text-inverse);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: var(--color-secondary);

  transition: background-color 0.3s ease;

  &:hover, &:focus {
    opacity: 0.9;
  }

  &:focus {
    outline: 2px solid var(--color-text-inverse);
    outline-offset: 2px;
  }
`;

interface MainPageProps {
  csrfToken: string | null;
}

const MainPage: React.FC<MainPageProps> = ({ csrfToken }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(LoginContext);

  const handleClick = () => {
    navigate(isLoggedIn ? '/chat' : '/login');
  };

  return (
    <PageContainer>
      <Title>Welcome to the Main Page</Title>
      <StyledButton onClick={handleClick}>
        {isLoggedIn ? 'Go to Chat' : 'Try Chocolate Chat'}
      </StyledButton>
    </PageContainer>
  );
};

export default MainPage;
